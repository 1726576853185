import React, {
  useState, useEffect, useLayoutEffect, useRef, useMemo,
} from 'react';
import { graphql } from 'gatsby';
import { gql, useLazyQuery } from '@apollo/client';

import {
  Box,
  Heading,
  Text,
  Divider,
} from '@chakra-ui/react';

import Layout from '../components/layout';
import AutoComplete from '../components/AutoComplete';
import AsideBox from '../components/AsideBox';
import DataTable from '../components/DataTable';

const Explorer = ({ data, location }) => {
  const [occ, setOcc] = useState(null);

  const GET_JOB_TITLES = gql`
  query JobTitles($search: String!) {
    jobTitle(search: $search) {
      name
      occupationCode
    }
  }
`;

  const GET_RESULTS = gql`
    query Results($occupationCode:String!) {
      occupation(occupationCode: $occupationCode) {
        occupationCode
        jobTitles {
          name
        }
        topIndustries {
          industryTitle
          projection {
            employment2019
            percentChange
          }
        }
        educationRequirements:requirements(search:"minimum education") {
          requirementText
          requirementEstimate
          datatype
        }
        requirements(requirementCodes:"00122,00116,00098,01087,00108,00114,00065") {
          requirementText
          requirementEstimate
          datatype
        }
        estimateByLQ: topWageEstimates(by: locQuotient) {
          locQuotient
          totalEmployed
          jobs1000
          areaTitle
          hourlyMeanWage
          annualMeanWage
        }
        estimateByTotal: topWageEstimates(by: totalEmployed) {
          locQuotient
          totalEmployed
          jobs1000
          areaTitle
          hourlyMeanWage
          annualMeanWage
        }
      }
    }
  `;

  const [getResultData, { loading, data: resultData }] = useLazyQuery(GET_RESULTS);

  useEffect(() => {
    if (occ && occ.occupationCode) {
      console.log(occ.occupationCode);
      getResultData({ variables: { occupationCode: occ.occupationCode } });
    }
  }, [occ, getResultData]);

  console.log(resultData);

  const industryColumns = [
    {
      Header: 'Industry',
      accessor: 'industryTitle',
      isNumeric: false,
    },
    {
      Header: 'Employment 2019',
      accessor: 'projection.employment2019',
      isNumeric: true,
    },
    {
      Header: '% change 2029',
      accessor: 'projection.percentChange',
      isNumeric: true,
    },
  ];

  const industryData = (resultData && resultData.occupation) ? resultData.occupation.topIndustries : [];

  const areasByEmploymentColumns = [
    {
      Header: 'Metro Area',
      accessor: 'areaTitle',
      isNumeric: false,
    },
    {
      Header: 'Employment',
      accessor: 'totalEmployed',
      isNumeric: true,
    },
    {
      Header: 'Employment/1000',
      accessor: 'jobs1000',
      isNumeric: true,
    },
    {
      Header: 'LQ',
      accessor: 'locQuotient',
      isNumeric: true,
    },
    {
      Header: 'Median Hourly Wage',
      accessor: 'hourlyMeanWage',
      isNumeric: true,
    },
    {
      Header: 'Median Annual Wage',
      accessor: 'annualMeanWage',
      isNumeric: true,
    },
  ];

  const areasByEmploymentData = (resultData && resultData.occupation) ? resultData.occupation.estimateByTotal : [];

  const areasByLQColumns = [
    {
      Header: 'Metro Area',
      accessor: 'areaTitle',
      isNumeric: false,
    },
    {
      Header: 'Employment',
      accessor: 'totalEmployed',
      isNumeric: true,
    },
    {
      Header: 'Employment/1000',
      accessor: 'jobs1000',
      isNumeric: true,
    },
    {
      Header: 'LQ',
      accessor: 'locQuotient',
      isNumeric: true,
    },
    {
      Header: 'Median Hourly Wage',
      accessor: 'hourlyMeanWage',
      isNumeric: true,
    },
    {
      Header: 'Median Annual Wage',
      accessor: 'annualMeanWage',
      isNumeric: true,
    },
  ];

  const areasByLQData = (resultData && resultData.occupation) ? resultData.occupation.estimateByLQ : [];

  const requirementsColumns = [
    {
      Header: 'Requirement',
      accessor: 'requirementText',
      isNumeric: false,
    },
    {
      Header: 'Value',
      accessor: 'requirementEstimate',
      isNumeric: true,
    },
    {
      Header: 'Unit',
      accessor: 'datatype',
      isNumeric: false,
    },
  ];

  const requirementsData = (resultData && resultData.occupation) ? [...resultData.occupation.requirements, ...resultData.occupation.educationRequirements] : [];

  return (
    <Layout title="explorer" location={location}>
      <Heading as="h1" fontSize="6xl" mb="10">Explorer</Heading>
      <Box>
        <Heading size="md" mb="4">Job Title Search</Heading>
        <AutoComplete
          query={GET_JOB_TITLES}
          queryVariableName="search"
          suggestionDisplayField="name"
          suggestionResultCallback={job => job.jobTitle}
          result={occ}
          setResult={setOcc}
          placeholder="enter job title"
        />
      </Box>
      <Divider />
      {occ && resultData
      && (
      <Box>

    <AsideBox>
          <Heading size="l" mb="4" color="gray" textTransform="uppercase">Job titles</Heading>
          <Text fontSize="md" mb="4">
            Occupation Code: {resultData && resultData.occupation.occupationCode}
          </Text>
          <Text fontSize="sm">
            { resultData.occupation.jobTitles.map(jt => jt.name).join(', ') }
          </Text>
        </AsideBox>

        <AsideBox>
          <Heading size="l" mb="4" color="gray" textTransform="uppercase">Top Industries</Heading>
          <DataTable columns={industryColumns} data={industryData} />
        </AsideBox>

        <AsideBox>
          <Heading size="l" mb="4" color="gray" textTransform="uppercase">Areas by Employment Volume</Heading>
          <DataTable columns={areasByEmploymentColumns} data={areasByEmploymentData} />
        </AsideBox>

        <AsideBox>
          <Heading size="l" mb="4" color="gray" textTransform="uppercase">Areas by LQ</Heading>
          <DataTable columns={areasByLQColumns} data={areasByLQData} />
        </AsideBox>

        <AsideBox>
          <Heading size="l" mb="4" color="gray" textTransform="uppercase">Estimated training requirements</Heading>
          <DataTable columns={requirementsColumns} data={requirementsData} />
        </AsideBox>
      </Box>
      )}
    </Layout>
  );
};

export default Explorer;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
